import { httpClient } from "@common/Libs/axios";
import {
  CorporateClientDetailType,
  CorporateClientsType,
  CreateCorporateClientType,
} from "@common/Types";

import { corporateClientsPath } from "./paths";

export const corporateClientsService = {
  getCorporateClients: (skip: number, limit: number) =>
    httpClient.get<CorporateClientsType>(
      corporateClientsPath.corporate_clients,
      {
        params: { skip, limit },
      },
    ),
  createCorporateClients: (data: CreateCorporateClientType) =>
    httpClient.post<CorporateClientDetailType, CreateCorporateClientType>(
      corporateClientsPath.corporate_clients,
      data,
    ),
};
