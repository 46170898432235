/**
 * Сервисы для corporate-clients
 */

import { appConfig } from "@api/config";

const src = appConfig.api.corporate_clients;

export const corporateClientsPath = {
  corporate_clients: `${src}`,
};
