import { useQuery } from "@tanstack/react-query";

import { QUERY_KEYS } from "@api/config";
import { ridesService } from "@api/services";
import { QueryParams, RidesFiltersType } from "@common/Types";

export const useGetRides = (params: QueryParams<RidesFiltersType>) => {
  return useQuery({
    queryKey: [QUERY_KEYS.RIDES, params],
    queryFn: () => ridesService.getRides(params),
  });
};
