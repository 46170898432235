import { omit } from "radash";

export enum PARK_STATUSES {
  ACTIVE = "active",
  INACTIVE = "inactive",
}

export enum TRANSACTION_TYPES {
  WITHDRAW = "withdraw",
  REPLENISHMENT = "replenishment",
}

export enum ACTIVATION_TYPES {
  ACTIVATION = "activation",
  DEACTIVATION = "deactivation",
}

export enum PARK_APPLICATIONS_STATUSES {
  NEW = "new",
  APPROVED = "approved",
}

export enum APPLICATION_STATUSES {
  NEW = "new",
  REJECTED = "rejected",
  APPROVED = "approved",
}

export enum FRAUD_STATUSES {
  CREATED = "created",
  IN_MODERATION = "in_moderation",
  BLOCKED = "blocked",
  UNLOCKED = "unlocked",
  APPROVE = "approve",
  DECLINE = "decline",
}

export enum ROLES_MOTOR {
  SUPERADMIN = "superadmin",
  CITYSUPERADMIN = "citysuperadmin",
  MOTORADMIN = "dispatcher",
  PARKADMIN = "parkadmin",
  DRIVER = "driver",
  RIDER = "rider",
}

export enum ROLES_NAVI {
  SUPERADMIN = "superadmin",
  CITYSUPERADMIN = "citysuperadmin",
  NAVIADMIN = "dispatcher",
  PARKADMIN = "parkadmin",
  DRIVER = "driver",
  RIDER = "rider",
}

export const ROLES = { ...ROLES_MOTOR, ...ROLES_NAVI };

export const MAIN_ROLES = Object.values(omit(ROLES, ["RIDER", "DRIVER"]));

export const APP_BUILD_TARGET = process.env.REACT_APP_BUILD_TARGET;

export const TOKEN = {
  ACCESS_TOKEN: `${APP_BUILD_TARGET}_access_token`,
  REFRESH_TOKEN: `${APP_BUILD_TARGET}_refresh_token`,
} as const;

export enum WS_TYPE {
  DRIVERS = "driver-support-message",
  RIDERS = "rider-support-message",
}

export enum APP_TYPE {
  IOS = "ios-rider",
  ANDROID_DRIVER = "android-driver",
  ANDROID_RIDER = "android-rider",
}

export const formatOfDate = "dd-MM-yyyy";

export const separatedFormatOfDate = "yyyy-MM-dd";

export enum ReportDriverBalanceStatus {
  new = "новый",
  processing = "в процессе",
  processed = "обработанный",
  create_error = "ошибка при создании",
}

export const ReportDriverBalanceType = {
  replenishment_by_driver: "Реестр по балансу",
  replenishment_by_ride: "Реестр по поездкам",
  withdraw_commission: "Реестр  по комиссии",
  withdraw_driver: "Реестр по выводу",
  promotions: "Реестр по акциям",
  rides: "Отчет по поездкам",
  drivers: "Отчет по водителям",
  riders: "Отчет по пассажирам",
  driver_ride_promotions: "Отчет по целям водителей",
} as const;
