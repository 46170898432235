import clsx from "clsx";
import { useFormikContext } from "formik";
import React from "react";

import { useGetCities } from "@api/queries";
import { useDelay, useDimension } from "@common/Hooks";
import { buildCitiesOption } from "@common/Utils";

import { CityIcon } from "../assets/icons";
import { FilterValueType } from "../common/types";
import { updateChips } from "../common/utils";

import { CustomSelect } from "./custom-select";

type Props<T> = {
  className?: string;
  field: keyof T;
  singleChoice?: boolean;
  title?: string;
};

export const CitiesFilter = <T,>({
  className,
  field,
  title = "Город",
  singleChoice = true,
}: Props<T>) => {
  const { isDesktop } = useDimension();
  const { values, setValues, submitForm } =
    useFormikContext<FilterValueType<T>>();
  const { response: cities } = useGetCities(0, 50);
  const debounceSubmitForm = useDelay(submitForm, 300);

  const options = buildCitiesOption(cities?.items);

  const handleSelectionChange = (selectedValue: string) => {
    const fullValue = options.find((option) => option.value === selectedValue);
    const label = fullValue?.label || "";
    const chips = values.chips || [];

    const updatedChips = updateChips(chips, field as string, label);

    setValues({
      ...values,
      chips: updatedChips,
      [field]: selectedValue,
    });

    if (isDesktop) {
      debounceSubmitForm();
    }
  };

  return (
    <CustomSelect
      value={values[field] as unknown as string}
      icon={<CityIcon />}
      label={isDesktop ? "" : title}
      data={options}
      className={clsx(className, "w-full")}
      onChange={handleSelectionChange}
      multiple={!singleChoice}
      placeholder="Выберите город"
    />
  );
};
