import React from "react";

import { StatusIcon } from "@assets/Icons";
import { STATUS } from "@common/Constants";

import { CheckboxList } from "../checkbox-list";

type Props<T> = {
  filters: T;
  setFilters: (filters: T) => void;
};

export const RideStatusFilter = <T,>({ filters, setFilters }: Props<T>) => {
  return (
    <CheckboxList
      MainIcon={<StatusIcon />}
      title="Статус"
      filters={filters}
      setFilters={setFilters}
      field={"status" as keyof T}
      options={STATUS}
    />
  );
};
