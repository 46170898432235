import { useFormikContext } from "formik";
import React from "react";

import { useGetTariffs, useGetTariffsById } from "@api/queries";
import { useDelay, useDimension } from "@common/Hooks";

import { TariffIcon } from "../assets/icons";
import { FilterValueType } from "../common/types";
import { buildTariffsFilter, updateChips } from "../common/utils";

import { CustomSelect } from "./custom-select";

type Props<T> = {
  field: keyof T;
  selectedCity?: number;
};

export const TariffsFilter = <T,>({ field, selectedCity }: Props<T>) => {
  const { isDesktop } = useDimension();

  const { values, setValues, submitForm } =
    useFormikContext<FilterValueType<T>>();

  const debounceSubmitForm = useDelay(submitForm, 300);

  const { data: tariffs = [] } = useGetTariffs({ enabled: !selectedCity });
  const { data: tariffsByCity = [] } = useGetTariffsById({
    city_id: selectedCity as number,
    enabled: !!selectedCity,
  });

  const options = buildTariffsFilter(selectedCity ? tariffsByCity : tariffs);

  const handleSelect = (selectedValue: string) => {
    const fullValue = options.find((option) => option.value === selectedValue);
    const label = fullValue?.label || "";
    const chips = values.chips || [];

    const updatedChips = updateChips(chips, field as string, label);

    setValues({ ...values, [field]: selectedValue, chips: updatedChips });

    if (isDesktop) {
      debounceSubmitForm();
    }
  };

  return (
    <CustomSelect
      value={values[field] as unknown as string}
      label={isDesktop ? "" : "Тариф"}
      data={options}
      icon={<TariffIcon />}
      placeholder="Выберите тариф"
      onChange={handleSelect}
    />
  );
};
