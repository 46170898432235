import { useQuery } from "@tanstack/react-query";

import { QUERY_KEYS } from "@api/config";
import { ridesService } from "@api/services";
import { QueryParams, RidesReviewFiltersType } from "@common/Types";

export const useGetRidesReview = (
  params: QueryParams<RidesReviewFiltersType>,
) => {
  return useQuery({
    queryKey: [QUERY_KEYS.RIDES_REVIEW, params],
    queryFn: () => ridesService.getRidesReview(params),
  });
};
