import { Input } from "@mantine/core";
import { useDebouncedValue } from "@mantine/hooks";
import { useFormikContext } from "formik";
import React from "react";
import { twMerge } from "tailwind-merge";

import { useDelay, useDimension } from "@common/Hooks";

import { MagnifyingGlassIcon } from "../assets/icons";

type Props<T> = {
  field: keyof T;
  width?: string;
  className?: string;
  rightSection?: React.ReactNode;
  placeholder?: string;
};

export const SearchFilters = <T,>({
  placeholder,
  field,
  width,
  className,
  rightSection,
}: Props<T>) => {
  const { isDesktop } = useDimension();
  const { values, setFieldValue, submitForm } = useFormikContext<T>();
  const [localValue, setLocalValue] = React.useState<string>(
    (values[field] as unknown as string) || "",
  );

  const debounceSubmitForm = useDelay(submitForm, 300);
  const [debouncedValue] = useDebouncedValue(localValue, 300);

  React.useEffect(() => {
    setFieldValue(field as string, debouncedValue);

    if (isDesktop) {
      debounceSubmitForm();
    }
  }, [debouncedValue]);

  const onChange = ({
    target: { value },
  }: React.ChangeEvent<HTMLInputElement>) => {
    setLocalValue(value);
  };

  return (
    <div className={twMerge("self-center w-full", className)}>
      <Input
        className={`sm:w-full md:${width ? width : "w-72"} rounded-[4px]`}
        icon={<MagnifyingGlassIcon />}
        classNames={{
          input:
            "h-[40px] border border-neutral-300 py-[7px] text-body-1-b  pl-[43px] focus:shadow-none",
          icon: "pl-[14px]",
        }}
        placeholder={placeholder}
        value={localValue}
        onChange={onChange}
        rightSection={rightSection}
      />
    </div>
  );
};
