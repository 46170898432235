import { CreditCard } from "phosphor-react";
import React from "react";

import { PAYMENT_FILTERS } from "@common/Constants";
import { FilterTabType } from "@common/Types";
import { parseProviders } from "@common/Utils";

import { CheckboxList } from "../checkbox-list";

type Props<T> = {
  filters: T;
  setFilters: (value: T) => void;
};

export const PaymentFilter = <T,>({ filters, setFilters }: Props<T>) => {
  const newPaymentFilters = PAYMENT_FILTERS.filter(
    (filter) => filter.value !== "all",
  );

  const options =
    newPaymentFilters?.map(({ label, value }: FilterTabType) => ({
      label,
      value,
    })) || [];

  return (
    <CheckboxList
      MainIcon={<CreditCard />}
      title="Вид платежа"
      filters={filters}
      setFilters={setFilters}
      field={"payment_type" as keyof T}
      options={parseProviders(options)}
      singleChoice={true}
    />
  );
};
