import { omit } from "radash";

import { httpClient } from "@common/Libs/axios";
import {
  CreateDriverType,
  DriverActionsResponse,
  DriverActionsType,
  DriverActivationType,
  DriverChangeHistoryResponse,
  DriverDetailType,
  DriverEventsType,
  DriverGroupBalanceType,
  DriverHistoryResponse,
  DriverOperationResponse,
  DriverOperationType,
  DriverOperationValues,
  DriverPromotionType,
  DriverQueryParams,
  DriverRidesStatisticsQueryParams,
  DriversLocationType,
  DriverStatusesCountQueryParams,
  DriverStatusesType,
  DriverTransactionResponse,
  DriverType,
  DriverTypes,
  IDType,
  initialDriverType,
  LandingDriverApplicationType,
  QueryParams,
  RidesEnrouteFiltersType,
  StatisticRides,
  StatisticRidesResponse,
  StatisticsQueryParams,
  StatusStatisticsAverageResponse,
  StatusStatisticsResponse,
  StatusStatisticsTotalResponse,
} from "@common/Types";
import {
  buildCommonDateFilters,
  buildCommonFiltersValues,
  commaSerializer,
  formatDate,
  removeEmptyFields,
} from "@common/Utils";

import { driverPath } from "./paths";

/**
 * Описание методов drivers.
 */

export const driversService = {
  getDriverLocation: (filters?: DriversLocationType) =>
    httpClient.get<DriverTypes>(driverPath.locations, {
      params: buildCommonFiltersValues(filters),
      paramsSerializer: commaSerializer,
    }),

  getDrivers: ({ limit, skip, filters }: QueryParams) => {
    const buildFilter = buildCommonFiltersValues(filters);

    return httpClient.get<DriverType>(driverPath.drivers, {
      params: { limit, skip, ...buildFilter },
      paramsSerializer: commaSerializer,
    });
  },

  sendDriverLandingApplication: (application: LandingDriverApplicationType) =>
    httpClient.post(driverPath.support_applications, application),

  createDriver: (driver: CreateDriverType) =>
    httpClient.post<CreateDriverType, CreateDriverType>(
      driverPath.create_driver,
      driver,
    ),

  updateDriver: (driver: initialDriverType) =>
    httpClient.put(driverPath.update_driver(driver.id), driver),

  getDriverDetail: (driverId?: IDType) =>
    httpClient.get<DriverDetailType>(driverPath.get_driver_detail(driverId)),

  setDriverBalance: (params: DriverOperationType) => {
    const { driverId, values } = params;

    return httpClient.post<DriverOperationResponse, DriverOperationValues>(
      driverPath.set_driver_balance(driverId),
      values,
    );
  },

  setDriverBonus: (params: DriverOperationType) => {
    const { driverId, values } = params;

    return httpClient.post<DriverOperationResponse, DriverOperationValues>(
      driverPath.set_driver_bonus(driverId),
      values,
    );
  },

  setDriverActivation: (params: DriverActivationType) => {
    const { driverId, values } = params;

    return httpClient.patch(driverPath.set_driver_activation(driverId), values);
  },

  getDriverStatistics: (params: DriverQueryParams) => {
    const { driver_id, filters, ...rest } = params;

    const filteredDate = buildCommonDateFilters(filters, [
      "date_range",
      "tariffs",
      "ride_status",
      "select_range",
    ]);

    return httpClient.get<DriverHistoryResponse>(
      driverPath.get_driver_statistics(driver_id),
      {
        params: { ...filteredDate, ...rest },
        paramsSerializer: commaSerializer,
      },
    );
  },

  getDriverStatisticsRide: (params: DriverQueryParams) => {
    const { driver_id, filters, ...rest } = params;

    return httpClient.get<DriverPromotionType>(
      driverPath.get_driver_statistics_ride(driver_id),
      {
        params: { ...rest, ...filters },
        paramsSerializer: commaSerializer,
      },
    );
  },

  getDriverStatisticsRides: (params: DriverRidesStatisticsQueryParams) => {
    const queryParams = removeEmptyFields(params);

    return httpClient.get<StatisticRides[]>(
      driverPath.driver_statistics_rides(),
      {
        params: queryParams,
        paramsSerializer: commaSerializer,
      },
    );
  },

  getDriverStatusCount: (params: DriverStatusesCountQueryParams) => {
    const queryParams = removeEmptyFields(params);

    return httpClient.get<DriverStatusesType[]>(
      driverPath.driver_status_count(),
      {
        params: queryParams,
        paramsSerializer: commaSerializer,
      },
    );
  },

  getDriverTransactions: (params: DriverQueryParams) => {
    const { driver_id, filters, ...rest } = params;

    return httpClient.get<DriverTransactionResponse>(
      driverPath.get_driver_transactions(driver_id),
      {
        params: { ...rest, ...filters },
        paramsSerializer: commaSerializer,
      },
    );
  },

  getDriverChangeHistory: (params: DriverQueryParams) => {
    const { driver_id, filters, ...rest } = params;

    return httpClient.get<DriverChangeHistoryResponse>(
      driverPath.get_driver_change_history(driver_id),
      {
        params: { ...rest, ...filters },
        paramsSerializer: commaSerializer,
      },
    );
  },

  sendPhotoControl: (driver_ids: IDType[]) =>
    httpClient.post(driverPath.send_photo_control, { driver_ids }),

  // status statistics
  driverStatusStatistics: ({
    driver_id,
    start_date,
    end_date,
  }: StatisticsQueryParams) => {
    return httpClient.get<StatusStatisticsResponse>(
      driverPath.driver_status_statistics,
      {
        params: {
          driver_id,
          start_date,
          end_date,
        },
      },
    );
  },

  driverStatusStatisticsAverage: ({
    driver_id,
    start_date,
    end_date,
  }: StatisticsQueryParams) => {
    return httpClient.get<StatusStatisticsAverageResponse>(
      driverPath.driver_status_statistics_average,
      {
        params: {
          driver_id,
          start_date,
          end_date,
        },
      },
    );
  },

  driverStatusStatisticsTotal: ({
    driver_id,
    start_date,
    end_date,
  }: StatisticsQueryParams) => {
    return httpClient.get<StatusStatisticsTotalResponse>(
      driverPath.driver_status_statistics_total,
      {
        params: {
          driver_id,
          start_date,
          end_date,
        },
      },
    );
  },

  driverRideStatistics: ({
    driver_id,
    start_date,
    end_date,
  }: StatisticsQueryParams) => {
    const startDate = formatDate(start_date);
    const endDate = formatDate(end_date);

    return httpClient.get<StatisticRidesResponse[]>(
      driverPath.driver_ride_statistics(driver_id),
      {
        params: { date_range: [startDate, endDate] },
        paramsSerializer: commaSerializer,
      },
    );
  },

  setDriverAction: (params: DriverActionsType) => {
    const { driver_id, comment, action } = params;

    return httpClient.post<
      DriverActionsResponse,
      Omit<DriverActionsType, "driver_id">
    >(
      driverPath.driver_action(driver_id),
      removeEmptyFields({ comment, action }),
    );
  },

  getDriverEvents: (params: QueryParams<RidesEnrouteFiltersType>) => {
    const { filters, ...rest } = params;

    return httpClient.get<DriverEventsType>(driverPath.driver_events, {
      params: { ...filters, ...rest },
      paramsSerializer: commaSerializer,
    });
  },

  createDriverGroupBalance: (data: DriverGroupBalanceType) => {
    const filteredData = omit(data, ["date_range"]);

    return httpClient.post<DriverGroupBalanceType, DriverGroupBalanceType>(
      driverPath.driver_group_balance,
      filteredData,
    );
  },
};
