import { Drawer } from "@mantine/core";
import { useFormikContext } from "formik";
import React, { useState } from "react";

import { CloseIcon } from "@assets/Icons";
import { Button, Col, Row, Typography } from "@components/Elements";

import { FilterIcon } from "../assets/icons";
import { hasSearchComponent } from "../common/utils";

import { ChipsFilter } from "./chips-filter";

type Props = {
  children: React.ReactNode | React.ReactNode[];
};

export const MobileFilter = ({ children }: Props) => {
  const [isOpenFilters, setOpenFilters] = useState(false);

  const { submitForm } = useFormikContext();

  const onSubmit = () => {
    submitForm();
    setOpenFilters(false);
  };

  const { searchComponent, remainingChildren } = React.useMemo(
    () => hasSearchComponent(children),
    [children],
  );

  return (
    <Row
      bgColor="neutral-0"
      alignItems="items-center"
      justifyContent="justify-between"
      className="gap-2 rounded-1 p-2 w-full"
    >
      <Button
        view="inner"
        onClick={() => setOpenFilters(true)}
        className="w-10 h-10"
      >
        <FilterIcon />
      </Button>
      {searchComponent}
      <Drawer
        size="98%"
        position="bottom"
        className="rounded-tl-lg rounded-tr-lg overflow-y-auto"
        opened={isOpenFilters}
        withCloseButton={false}
        closeOnClickOutside={false}
        onClose={() => setOpenFilters(false)}
      >
        <Row
          className="gap-4 px-4 py-2"
          alignItems="items-center"
          justifyContent="justify-between"
        >
          <Typography
            as="h3"
            variant="body-1-b"
            color="neutral-800"
            className="font-medium"
          >
            Фильтрация
          </Typography>
          <Button
            view="none"
            onClick={() => setOpenFilters(false)}
            className="w-10 h-10"
          >
            <CloseIcon />
          </Button>
        </Row>
        <Col className="gap-4 px-4 py-4">
          {remainingChildren}
          <ChipsFilter />
        </Col>

        <Row className="w-full mt-2 p-4" justifyContent="justify-end">
          <Button
            size="lg"
            view="green"
            className="z-30 w-full sticky top-[100%]"
            onClick={onSubmit}
          >
            Применить
          </Button>
        </Row>
      </Drawer>
    </Row>
  );
};
