import React from "react";

import { Grid } from "@components/Elements";

type Props = {
  children: React.ReactNode | React.ReactNode[];
};

export const DesktopFilter = ({ children }: Props) => {
  return (
    <Grid columns="auto-fill" minColumnWidth="280px">
      {children}
    </Grid>
  );
};
