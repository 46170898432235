import {
  DateRageFilterType,
  IDType,
  PredefinedDateRangeType,
  TransactionsFiltersType,
} from "@common/Types";

export enum TransactionTypeTypes {
  BonusWithdraw = "bonus_withdraw",
  BonusAdd = "bonus_add",
  BalanceWithdraw = "balance_withdraw",
  BalanceAdd = "balance_add",
  BonusesAdd = "bonuses_add",
  bankAccountAdd = "bank_account_add",
  bankAccountWithdraw = "bank_account_withdraw",
}

export type TransactionDetailType = {
  amount: number;
  car_plate_number: string;
  created_at: Date;
  description: string;
  driver_id: number;
  first_name: string;
  id: number;
  last_name: string;
  second_name: string;
  status: string;
  type: string;
};

export type TransactionMainType = {
  items: TransactionDetailType[];
  length: number;
};

export type TransactionsParams = {
  filters: TransactionsFiltersType;
  skip: number;
  limit: number;
};

export type AdminTransactionsParams = {
  filters: AdminTransactionsInitialFilters;
  skip: number;
  limit: number;
};

export type WithdrawTransactionDetailType = {
  id: number;
  driver_id: number;
  driver_first_name: string;
  driver_last_name: string;
  driver_second_name: string;
  driver_phone: string;
  amount: number;
  commission_amount: number;
  description: string;
  currency: string;
  created_at: string;
};

export type WithdrawTransactionsType = {
  items: WithdrawTransactionDetailType[];
  length: number;
};

export type AdminTransaction = {
  items: AdminTransactionDetailsType[];
  length: number;
};

export interface AdminTransactionDetailsType {
  admin_user_id: number;
  driver_id: number;
  rider_id: number;
  description: string;
  amount: number;
  transaction_type: TransactionTypeTypes;
  created_at: Date;
  admin_user?: {
    id: number;
    first_name: string;
    last_name: string;
    second_name: string;
  };
  driver_user?: {
    id: number;
    first_name: string;
    last_name: string;
    second_name: string;
  };
  rider_user?: {
    id: number;
    first_name: string;
    last_name: string;
    second_name: string;
  };
}

export type AdminTransactionsInitialFilters = {
  start_date: string | null;
  end_date: string | null;
  search_term: string;
  transaction_type: string;
  date_range?: DateRageFilterType;
  select_range?: PredefinedDateRangeType;
};

// bank accounts
export type BankAccountQuery = {
  skip: number;
  limit: number;
};

export type BankAccountItemType = {
  id: IDType;
  name: BankAccountPaymentTypes;
  balance: number;
  last_transaction_date: string;
};

export type BankAccountListType = {
  length: number;
  items: BankAccountItemType[];
};

export type BankAccountBalanceResponseType = {
  id: IDType;
  bank_account_id: number;
  admin_user_id: number;
  driver_id: number;
  type: string;
  amount: number;
  description: string;
  created_at: string;
};

export type BankAccountBalanceType = {
  type: string;
  amount: number;
  description?: string;
};

export type BankAccountBalanceParams = {
  account_id: IDType;
  body: BankAccountBalanceType;
};

export type BankAccountPaymentTypes = "freedom" | "epay" | "altyn" | "mbank";
