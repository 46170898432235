import { Children, isValidElement, ReactNode } from "react";

import { FilterTabType, TariffType } from "@common/Types";

import { SearchFilters } from "../components/search-filter";

import { FilterChipType } from "./types";

export const hasSearchComponent = (children: ReactNode) => {
  let searchComponent: ReactNode | null = null;
  const remainingChildren: ReactNode[] = [];

  Children.forEach(children, (child) => {
    if (isValidElement(child) && child.type === SearchFilters) {
      searchComponent = child;
    } else {
      remainingChildren.push(child);
    }
  });

  return { searchComponent, remainingChildren };
};

export const buildTariffsFilter = (parks: TariffType[]): FilterTabType[] => {
  return parks.map((park) => ({
    label: park.name,
    value: park.id.toString(),
  }));
};

// helper for filter chips (applied filter list)
export const updateChips = (
  chips: FilterChipType[],
  field: string,
  label: string,
) => {
  const initialChips = chips.some((chip) => chip.field === field)
    ? []
    : [{ field: field, list: [label] }];

  return chips.reduce((acc, chip) => {
    if (chip.field === field) {
      return [...acc, { ...chip, list: [...chip.list, label] }];
    }
    return [...acc, chip];
  }, initialChips);
};
